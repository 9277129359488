
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { UtilisateurInterface } from "@/models/utilisateurs/Utilisateur.interface";
import apiClient from "@/api-client";
import Card from "primevue/card";
import InputSwitch from "primevue/inputswitch";
import { useUsersStore } from "@/store/users";
import router from "@/router";

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    InputSwitch,
  },
  props: {
    utilisateurId: {
      type: String,
    },
  },
  setup(props) {
    const toast = useToast();
    const utilisateur = ref<UtilisateurInterface>();
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    utilisateur.value = new UtilisateurInterface();
    utilisateur.value.id = props.utilisateurId!;

    if (props.utilisateurId != 'notSet') {
      apiClient.utilisateurs
        .fetchUtilisateur(utilisateur.value)
        .then((data: UtilisateurInterface) => {
          utilisateur.value = Object.assign(new UtilisateurInterface(), data);
        });
        
    }

    const saveUtilisateur = () => {
      apiClient.utilisateurs
        .sendUtilisateur(utilisateur.value!)
        .then((data: UtilisateurInterface) => {
          utilisateur.value = data;
          toast.add({
            severity: "success",
            summary: "Enregistré",
            detail: "L'utilisateur a été enregistré avec succès.",
            life: 5000,
          });
          router.push({ path: "/utilisateurs"});
        });
    };

    return {
      utilisateur,
      saveUtilisateur,
      appUser,
      can: usersStore.can,
    };
  },
});
