export default class LigneCommande {
    id?: number
    name?: string
    invoice_status?: string
    product_uom_qty?: number
    state?: string
    order?: {
        id: number
        name: string
        state: string
        date_order: Date
        invoice_status: string
        partner: {
            id: number
            display_name: string
            active: boolean
        }
    }
    product?: {
        id: number
        default_code: string
        active: boolean
        product_tmpl: {
            id: number
            name: string
            active: boolean
            x_spirit: boolean
        }
    }
    is_selected: boolean

    constructor(){
        this.is_selected = false
    }
}