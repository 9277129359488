import moment from "moment";

export default class Projet {
  id: string;
  name?: string;
  description?: string;
  dateCreation?: string;
  dateDebut?: string;
  dateFin?: string;
  dateDeadline?: string;
  status?: string;
  etapesProjet?: any[];
  saleOrderLines?: number[];
  referent?: string;
  etapeEnCours?: any;
  localpath?: string;
  commentaires?: string;

  constructor(){
      this.id = 'notSet';
      this.name = '';
      this.description = '';
      // this.dateCreation = new Date();
      this.status='Nouveau';
      this.etapesProjet = [];
      this.saleOrderLines = [];
  }

  get dateCreationTimestamp(): string {
    return moment(this.dateCreation, "DD/MM/YYYY HH:mm").format("X");
  }

  get dateDebutTimestamp(): string {
    return moment(this.dateDebut, "DD/MM/YYYY HH:mm").format("X");
  }

  get dateFinTimestamp(): string {
    return moment(this.dateFin, "DD/MM/YYYY HH:mm").format("X");
  }

  get dateDeadlineTimestamp(): string {
    return moment(this.dateDeadline, "DD/MM/YYYY HH:mm").format("X");
  }
}

export class NewProjetParams {
  modele: string;
  saleOrderLines: number[];

  constructor(modele: string, saleOrderLines: number[]){
    this.modele = modele;
    this.saleOrderLines = saleOrderLines;
  }
}