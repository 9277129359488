import { ProjetEtapeApiClientModel, ProjetEtapeApiClientInterface, ProjetEtapeApiClientUrlsInterface } from '@/models/api-client/projet_etape'

const urls: ProjetEtapeApiClientUrlsInterface = {
    getProjetEtape: process.env.VUE_APP_API_URL + '/api/projets/etapes/$(projet_etapeId)',
    getProjetEtapes: process.env.VUE_APP_API_URL + '/api/projets/$(projetId)/etapes',
    postProjetEtape: process.env.VUE_APP_API_URL + '/api/projets/$(projetId)/etapes',
    putProjetEtape: process.env.VUE_APP_API_URL + '/api/projets/etapes/$(projet_etapeId)',
    deleteProjetEtape: process.env.VUE_APP_API_URL + '/api/projets/etapes/$(projet_etapeId)'
}

const projet_etapeApiClient: ProjetEtapeApiClientInterface = new ProjetEtapeApiClientModel(urls)
// export our instance
export default projet_etapeApiClient
