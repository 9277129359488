export class UtilisateurInterface {
    id: string
    email?: string
    statut?: number
    is_active?: boolean
    is_superuser?: boolean
    first_name?: string
    last_name?: string
    date_statut?: Date
    is_operateur?: boolean
    is_referent?: boolean
    is_admin_projets?: boolean
    is_admin_modeles?: boolean
    groups?: string[]
    user_permissions?: string[]

    constructor(){
        this.id = 'notSet';
    }

    get full_name(): string {
        return this.first_name + ' ' + this.last_name;
    }
    // public set full_name(name: string) {

    // }

}