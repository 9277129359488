import Projet from '@/models/projet/Projet'
import {NewProjetParams} from '@/models/projet/Projet'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

export interface ProjetApiClientInterface {
    getProjets: (apiFilters?: any) => Promise<Projet[]>
    getProjet: (projet: Projet) => Promise<Projet>
    sendProjet: (projet: Projet) => Promise<Projet>
    deleteProjet: (projet: Projet) => Promise<Projet>
    newProjetParams: (params: NewProjetParams) => Promise<Projet>
}

export interface ProjetApiClientUrlsInterface {
    getProjets: string
    getProjet: string
    postProjet: string
    putProjet: string
    deleteProjet: string
    newProjetParams: string
}

export class ProjetApiClientModel implements ProjetApiClientInterface {
    private readonly urls!: ProjetApiClientUrlsInterface
    constructor(urls: ProjetApiClientUrlsInterface) {
        this.urls = urls
    }

    getProjets(apiFilters?: any): Promise<Projet[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getProjets,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Projet[]>(getParameters)
    }
    getProjet(projet: Projet): Promise<Projet> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getProjet.replace('$(projetId)', projet.id),
            requiresToken: false
        }
        return HttpClient.get<Projet>(getParameters)
    }
    sendProjet(projet: Projet): Promise<Projet> {
        if (projet.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putProjet.replace('$(projetId)', projet.id),
                requiresToken: false,
                payload: projet,
            }
            return HttpClient.put<Projet>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postProjet,
                requiresToken: false,
                payload: projet,
            }
            return HttpClient.post<Projet>(getParameters)
        }
    }
    deleteProjet(projet: Projet): Promise<Projet> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteProjet.replace('$(projetId)', projet.id),
            requiresToken: false
        }
        return HttpClient.delete<Projet>(getParameters)
    }

    newProjetParams(params: NewProjetParams): Promise<Projet> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.newProjetParams,
            requiresToken: false,
            payload: params,
        }
        return HttpClient.post<Projet>(getParameters)
    }
}
