import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modele-projet-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjetForm = _resolveComponent("ProjetForm")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_ProjetForm, { projetId: _ctx.projetId }, null, 8, ["projetId"])
  ]))
}