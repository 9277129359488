import { UsersApiClientUrlsInterface } from './UsersApiClientUrls.interface'
import { UsersApiClientInterface } from './UsersApiClient.interface'
import { UserInterface } from '@/models/users/User.interface'
import { ActionInterface } from '@/models/actions/Action.interface'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import Invitation from '@/models/users/Invitation'

/**
* @Name UsersApiClientModel
* @description
* Implements the UsersApiClientInterface interface
*/
export class UsersApiClientModel implements UsersApiClientInterface {
    private readonly urls!: UsersApiClientUrlsInterface
    constructor(urls: UsersApiClientUrlsInterface) {
        this.urls = urls
    }

    getToken(username: string, password: string): Promise<UserInterface> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getToken,
            requiresToken: false,
            payload: {username: username, password: password}
        }
        return HttpClient.post<UserInterface>(getParameters)
    }

    resetPassword(email: string): Promise<UserInterface> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.resetPassword.replace('$(email)', email),
            requiresToken: false,
        }
        return HttpClient.post<UserInterface>(getParameters)
    }

    changePassword(action: ActionInterface): Promise<UserInterface> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.changePassword.replace('$(actionId)', action.id),
            requiresToken: false,
            payload: {action: action}
        }
        return HttpClient.put<UserInterface>(getParameters)
    }

    simulateUser(userId: string): Promise<UserInterface> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.simulateUser.replace('$(userId)', userId),
            requiresToken: false
        }
        return HttpClient.get<UserInterface>(getParameters)
    }

    inviterUtilisateur(invitation: Invitation): Promise<UserInterface> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.inviterUtilisateur,
            requiresToken: false,
            payload: invitation,
        }
        return HttpClient.post<UserInterface>(getParameters)
    }
}
