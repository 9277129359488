import Product from "../product/Product";

export default class ModeleProjet {
  id: string;
  name?: string;
  duree_jours?: number;
  products?: Product[];
  etapesModele?: string[];

  constructor(){
      this.id = 'notSet';
      this.name = '';
      this.duree_jours = 1;
      this.products = [];
      this.etapesModele = [];
  }
}