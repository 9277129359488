import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-grid p-input-filled" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "p-col" }
const _hoisted_4 = { class: "box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppUserSimulationInfo = _resolveComponent("AppUserSimulationInfo")
  const _component_Toast = _resolveComponent("Toast")
  const _component_DahboardTopbar = _resolveComponent("DahboardTopbar")
  const _component_DashboardLeftMenu = _resolveComponent("DashboardLeftMenu")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_AppUserSimulationInfo),
    _createVNode(_component_Toast, { position: "top-right" }),
    _createVNode(_component_DahboardTopbar, { toggleDisplayMenu: _ctx.toggleDisplayMenu }, null, 8, ["toggleDisplayMenu"]),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", {
        class: ["p-col-fixed left-menu", { 'menu-hidden': _ctx.isMenuHidden }],
        style: {"width":"250px"}
      }, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DashboardLeftMenu)
        ])
      ], 2),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ], 64))
}