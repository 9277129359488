
import { defineComponent, ref, onBeforeMount } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import Etape from "@/models/etapes/Etape";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Button from "primevue/button";
import Card from "primevue/card";
import apiClient from "@/api-client";
import InputSwitch from 'primevue/inputswitch';
import ColorPicker from 'primevue/colorpicker';

export default defineComponent({
  name: "EtapesPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
    InputSwitch,
    ColorPicker,
  },
  setup() {
    const usersStore = useUsersStore();
    const etapes = ref<Etape[]>()
    const loading = ref(true);
    onBeforeMount(() => {
      loading.value = true;
      apiClient.etapes
        .getEtapes()
        .then((data: Etape[]) => {
          etapes.value = data.map((etape) => Object.assign(new Etape(), etape));
          loading.value = false;
        });
    });

    const onSelectEtape = (etape: Etape) => {
      console.log('ok')
    };

    return {
      etapes,
      onSelectEtape,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
      loading,
    };
  },
});
