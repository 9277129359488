
import { defineComponent, ref, onBeforeMount } from "vue";
import { useUsersStore } from "@/store/users";
import { useToast } from "primevue/usetoast";
// import { MutationType } from "@/models/store";
import LigneCommande from "@/models/ligne_commande/Ligne_commande";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import Tag from "primevue/tag";
import Button from "primevue/button";
import Card from "primevue/card";
import apiClient from "@/api-client";
import InputSwitch from 'primevue/inputswitch';
// import ColorPicker from 'primevue/colorpicker';
import moment from 'moment';
import Projet from "@/models/projet/Projet";
import {NewProjetParams} from "@/models/projet/Projet";
import ModeleProjet from "@/models/modele_projet/Modele_projet";
import Dropdown from 'primevue/dropdown';
import router from "@/router";
import {Mutex} from 'async-mutex';

export default defineComponent({
  name: "LignesCommandePage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
    InputSwitch,
    Dropdown,
  },
  setup() {
    const usersStore = useUsersStore();
    const lignes_commande = ref<LigneCommande[]>();
    const toast = useToast();
    const modeles_projet = ref<ModeleProjet[]>();
    const selected_modele = ref<ModeleProjet>();
    const modele_sans = ref<ModeleProjet>();
    const mutex = new Mutex();
    const loading = ref(true);

    modele_sans.value = new ModeleProjet();
    modele_sans.value.id = 'sans';
    modele_sans.value.name = 'Sans modèle';

    onBeforeMount(() => {
      loading.value = true;
      apiClient.lignes_commande
        .getLignesCommande()
        .then((data: LigneCommande[]) => {
          lignes_commande.value = data.map((ligne_commande) => Object.assign(new LigneCommande(), ligne_commande));
          loading.value = false;
        });
    });

    const refreshModeles = async () => {
        const release = await mutex.acquire();

        modeles_projet.value = [];
        selected_modele.value = undefined;
        let product_ids: number[] = [];
        const commande_ids: number[] = [];

        // Recherche des articles concernés par les lignes sélectionnées
        for (const ligne of lignes_commande.value!) {
          if (ligne.is_selected) {
            if (ligne.order?.id != null) {
              commande_ids.push(ligne.order.id);
            }
            if (ligne.product?.id != null) {
              product_ids.push(ligne.product.id);
            }
          }
        }

        if ((new Set(commande_ids)).size > 1) {
          for (const ligne of lignes_commande.value!) {
            ligne.is_selected = false;
          }
          product_ids = [];
          toast.add({
            severity: "warn",
            summary: "Interdit",
            detail: "Les lignes de commande sélectionnées doivent appartenir à la même commande.",
            life: 5000,
          });
        }

        if (product_ids.length) {
          apiClient.modeles_projet
            .getModelesProjet({"product_ids": product_ids.toString()})
            .then((data: ModeleProjet[]) => {
              modeles_projet.value = data.map((modele_projet) => Object.assign(new ModeleProjet(), modele_projet));
              // S'il n'y a qu'un modèle qui correspond, on le sélectionne automatiquement
              if (modeles_projet.value.length == 1) selected_modele.value = modeles_projet.value[0];

              // On rajoute le faux modèle "Sans modèle"
              if (modele_sans?.value) modeles_projet.value.push(modele_sans?.value);

              release();
          });
        }
        else release();
    };

    const createProjet = () => {
      if (selected_modele.value) {
        const selected_lignes_commande: number[] = [];
        lignes_commande.value?.forEach((ligne) => {
          if (ligne.id && ligne.is_selected) {
            console.log(ligne.id);
            selected_lignes_commande.push(ligne.id);
          }
        });

        if (selected_lignes_commande.length) {
          const params = new NewProjetParams(selected_modele.value.id, selected_lignes_commande);

          apiClient.projet
            .newProjetParams(params)
            .then((data: Projet) => {
              toast.add({
                severity: "success",
                summary: "Créé",
                detail: "Le projet " + data.name + " a été créé avec succès.",
                life: 5000,
              });
              router.push({ path: "/projet/" + data.id });
            });
        }
      }
    };

    return {
      lignes_commande,
      // onSelectLigneCommande,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
      moment,
      createProjet,
      refreshModeles,
      modeles_projet,
      selected_modele,
      loading,
    };
  },
});
