
import { defineComponent, ref, onBeforeMount, computed } from "vue";
import { useUsersStore } from "@/store/users";
//import { MutationType } from "@/models/store";
import Projet from "@/models/projet/Projet";
//import Product from "@/models/product/Product";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
//import Tag from "primevue/tag";
import Button from "primevue/button";
import Card from "primevue/card";
import apiClient from "@/api-client";
//import Chip from 'primevue/chip';
import moment from 'moment';
//import ProjetEtape from "@/models/projet_etape/Projet_etape";
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: "EtapesPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
    Dropdown,
    //Chip,
  },
  setup() {
    const usersStore = useUsersStore();
    const projets_get = ref<Projet[]>();
    const projets = ref<Projet[]>([]);
    const apiFilter = ref<any>();
    const loading = ref(true);

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    const droits = ref<any[]>([]);
    const droit = ref<any>();

    const loadProjets = () => {
      loading.value = true;
       apiClient.projet
        .getProjets(apiFilter.value)
        .then((data: Projet[]) => {
          projets_get.value = data.map((projet) => Object.assign(new Projet(), projet));
          projets.value = [];
          for (const projet of projets_get.value) {
            for (const etape of projet!.etapesProjet!) {
              if (etape.dateDebutReelle != null && etape.dateFinReelle == null) {
                projet.etapeEnCours = etape;
                projet.etapeEnCours.dateFinPrevueTimestamp = moment(projet.etapeEnCours.dateFinPrevue, "DD/MM/YYYY HH:mm").format("X");
                projet.etapeEnCours.acteur.full_name = projet.etapeEnCours.acteur.first_name + ' ' + projet.etapeEnCours.acteur.last_name;
                break;
              }
            }
            projets.value.push(projet);
          }
          loading.value = false;
        });
    }

    onBeforeMount(() => {
      // Ajout des droits dans le dropdown
      if(appUser.value.is_admin_modeles)
        droits.value.push({name: 'Administrateur de projets', code: 'admin_projets'});
      if(appUser.value.is_referent)
        droits.value.push({name: 'Référent', code: 'referent'});
      if(appUser.value.is_operateur)
        droits.value.push({name: 'Opérateur', code: 'operateur'});

      // Initialisation du dropdown
      droit.value = droits.value[0];
      apiFilter.value = {'status': 'En cours', 'droit': droit.value.code};

      loadProjets();
    });

    const droitChange = () => {
      apiFilter.value.droit = droit.value.code;
      loadProjets();
    }

    return {
      projets,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
      moment,
      droits,
      droit,
      droitChange,
      loading,
    };
  },
});
