import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { MutationType, RootStateInterface, UsersStateInterface } from '@/models/store'
import { initialUsersState } from './initialState'
import { UserInterface } from '@/models/users/User.interface'
import apiClient from '@/api-client'
import router from "@/router";

export const mutations: MutationTree<UsersStateInterface> = {
    loadingAppUser(state: UsersStateInterface, params: { username: string; password: string }) {
        state.loading = true
        state.appUser.email = params.username
        state.appUser.password = params.password

    },
    loadedAppUser(state: UsersStateInterface, data) {
        state.appUser = data
        localStorage.setItem('appUser', JSON.stringify(state.appUser))
        state.loading = false
        router.push({ path: "/" })
    },
    loadedFromLocalstorageAppUser(state: UsersStateInterface, param) {
        state.appUser = param.appUser
    },
    logedoutAppUser(state: UsersStateInterface, param) {
        state.appUser = {} as UserInterface
        localStorage.setItem('appUser', JSON.stringify(state.appUser))
    },
    simulatedUser(state: UsersStateInterface, data) {
        state.appUser = data
        localStorage.setItem('appUser', JSON.stringify(state.appUser))
        state.loading = false
        router.push({ path: "/" });
    }
}

export const actions: ActionTree<UsersStateInterface, RootStateInterface> = {
    loadAppUser({ commit }, params: { username: string; password: string }) {
        commit(MutationType.users.loadingAppUser, params)

        apiClient.users.getToken(params.username, params.password).then((data: UserInterface) => {
            commit(MutationType.users.loadedAppUser, data)
        })
    },
    loadFromLocalstorageAppUser({ commit }, params: { appUser: UserInterface }) {
        commit(MutationType.users.loadedFromLocalstorageAppUser, params)
    },
    logoutAppUser({ commit }, params: {}) {
        commit(MutationType.users.logedoutAppUser, params)
    },
    simulateUser({ commit }, params: { userId: string }) {
        commit(MutationType.users.loadingAppUser, params)

        apiClient.users.simulateUser(params.userId).then((data: UserInterface) => {
            commit(MutationType.users.simulatedUser, data)
        })
    }
}

const namespaced = true
const state: UsersStateInterface = initialUsersState
export const usersState: Module<UsersStateInterface, RootStateInterface> = {
    namespaced,
    state,
    actions,
    mutations
}