import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "etape-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EtapeForm = _resolveComponent("EtapeForm")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_EtapeForm, { etapeId: _ctx.etapeId }, null, 8, ["etapeId"])
  ]))
}