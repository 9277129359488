
import { defineComponent, ref, computed, onBeforeMount } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import apiClient from "@/api-client";
import router from "@/router";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";
import { UtilisateurInterface } from "@/models/utilisateurs/Utilisateur.interface";
//import MultiSelect from 'primevue/multiselect';
import ProjetEtapeForm from '@/components/projet_etape/ProjetEtapeForm.vue'
import Projet from "@/models/projet/Projet";
import ProjetEtape from "@/models/projet_etape/Projet_etape";
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import moment from 'moment';
import OverlayPanel from 'primevue/overlaypanel';
import Etape from "@/models/etapes/Etape";

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    //MultiSelect,
    ProjetEtapeForm,
    Textarea,
    Calendar,
    Dropdown,
    OverlayPanel,
    InputSwitch,
  },
  props: {
    projetId: {
      type: String,
    },
  },
  setup(props) {
    const toast = useToast();
    const confirm = useConfirm();
    const projet = ref<Projet>();
    const usersStore = useUsersStore();
    const saveAll = ref<boolean>();
    const utilisateurs = ref<UtilisateurInterface[]>();
    const newEtapeExists = ref<boolean>();
    const op = ref();
    const text_ajout = ref<string>();
    const allEtapes = ref<boolean>();
    const etapesProjetLib = ref<ProjetEtape[]>();
    const etapes = ref<Etape[]>();
    const selectedEtape = ref<Etape>();
    selectedEtape.value = new Etape();


    saveAll.value = false;
    newEtapeExists.value = false;

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    projet.value = new Projet();
    projet.value.id = props.projetId!;

    const toggle = (event: any) => {
        op.value.toggle(event);
    };

    const saveProjet = (bSave = true) => {
      saveAll.value = bSave;
      const id = projet.value!.id;
      apiClient.projet
        .sendProjet(projet.value!)
        .then((data: Projet) => {
          projet.value = data;
          toast.add({
            severity: "success",
            summary: "Enregistré",
            detail: "Le projet a été enregistré avec succès.",
            life: 5000,
          });
          saveAll.value = false;
          // if (id != 'notSet') {
          //   router.push({ path: "/projets" });
          // } else {
          newEtapeExists.value = false;
          //router.push({path: "/projet/" + projet.value!.id});
          router.go(0);
          // }
        });
    };

    const valider_texte = () => {
        const new_text = "[ " + moment(new Date()).format("DD/MM/YYYY HH:mm") + " - " + appUser.value.first_name + " " + appUser.value.last_name + " ]\n" + text_ajout.value + "\n" + "-".repeat(60);
        if (projet.value!.commentaires == null) projet.value!.commentaires = new_text;
        else projet.value!.commentaires = new_text + "\n" + projet.value!.commentaires;
        text_ajout.value = "";
        op.value.hide();
        saveProjet(false);
    };

    onBeforeMount(() => {
      apiClient.etapes
      .getEtapes()
      .then((data: Etape[]) => {
        etapes.value = data.map((etape) => Object.assign(new Etape(), etape));
        if (props.projetId != 'notSet') {
          apiClient.projet
            .getProjet(projet.value!)
            .then((data: Projet) => {
              projet.value = Object.assign(new Projet(),data);
              if (projet.value.status!="En cours") {
                allEtapes.value = true;
              }
              apiClient.projet_etape
                .getProjetEtapes(projet.value!)
                .then((data: ProjetEtape[]) => {
                  etapesProjetLib.value = data;
                });
            });
        }
      });

      apiClient.utilisateurs
        .fetchUtilisateurs({"is_referent": true})
        .then((data: UtilisateurInterface[]) => {
          utilisateurs.value = data.map((utilisateur) => Object.assign(new UtilisateurInterface(), utilisateur));
        });
    });


    const newEtape = () => {
      const nao = moment(new Date());
      const newEtapeProjet = ref<ProjetEtape>();
      newEtapeProjet.value = new ProjetEtape();
      newEtapeProjet.value!.projet = projet.value!.id;
      newEtapeProjet.value!.name = selectedEtape.value!.name;
      newEtapeProjet.value!.couleur = selectedEtape.value!.couleur;
      newEtapeProjet.value!.for_operateur = selectedEtape.value!.for_operateur;
      newEtapeProjet.value!.for_referent = selectedEtape.value!.for_referent;
      newEtapeProjet.value!.dateDebutPrevue = nao.format("DD/MM/YYYY HH:mm");
      newEtapeProjet.value!.dateFinPrevue = nao.format("DD/MM/YYYY HH:mm");
      apiClient.projet_etape
          .sendProjetEtape(newEtapeProjet.value!)
          .then((data: ProjetEtape) => {
            projet.value!.etapesProjet!.push(data.id);
        });

      newEtapeExists.value = true;
    };


    const lancerProjet = async() => {
      //
      if (!projet.value!.dateDebut) {
        toast.add({severity: "error", summary: "Lancement du projet impossible", detail: "La date de début est obligatoire.", life: 5000});
        return;
      }
      if (!projet.value!.referent) {
        toast.add({severity: "error", summary: "Lancement du projet impossible", detail: "Le référent est obligatoire.", life: 5000});
        return;
      }

      if (!projet.value!.etapesProjet!.length) {
        toast.add({severity: "error", summary: "Lancement du projet impossible", detail: "Il n'y a aucune étape définie !", life: 5000});
        return;
      }

      // Vérification de la cohérence des étapes
      let bOK = true;
      const projet_etape = ref<ProjetEtape>();

      // projet.value!.etapesProjet?.forEach((etapeProjetId) => {
      for (const etapeProjetId of projet.value!.etapesProjet!) {
          projet_etape.value = new ProjetEtape();
          projet_etape.value!.id = etapeProjetId;

          const data = await apiClient.projet_etape.getProjetEtape(projet_etape.value);

          projet_etape.value = Object.assign(new ProjetEtape(), data);
          bOK = bOK && projet_etape.value.acteur != undefined && projet_etape.value.dateDebutPrevue != undefined && projet_etape.value.dateFinPrevue != undefined;
        }

      if (!bOK) {
        toast.add({severity: "error", summary: "Lancement du projet impossible", detail: "Les étapes ne sont pas complètes. Vérifiez les dates prévues et les acteurs.", life: 5000});
        return;
      }

      // recalcul des dates de début et de fin en fonction de la date du jour et la durée entre date de début et date de fin d'origine.
      const duree = moment(projet.value!.dateFin, "DD/MM/YYYY HH:mm").diff(moment(projet.value!.dateDebut, "DD/MM/YYYY HH:mm"));
      let nao = moment(new Date());
      projet.value!.dateDebut = nao.format("DD/MM/YYYY HH:mm");
      projet.value!.dateFin = nao.add(duree, "ms").format("DD/MM/YYYY HH:mm");

      // Initialisation de la date de démarrage prévue de la première étape
      projet_etape.value = new ProjetEtape();
      projet_etape.value!.id = projet.value!.etapesProjet![0];
      const data = await apiClient.projet_etape.getProjetEtape(projet_etape.value);
      projet_etape.value = Object.assign(new ProjetEtape(), data);
      nao = moment(new Date());
      projet_etape.value!.dateDebutReelle = nao.format("DD/MM/YYYY HH:mm");
      apiClient.projet_etape
        .sendProjetEtape(projet_etape.value!)
        .then((data: ProjetEtape) => {
          projet.value!.status = "En cours";
          projet.value!.etapesProjet = [];
          saveProjet(false);
        });
    };

    const reactiverProjet = async() => {

      const projet_etape = ref<ProjetEtape>();

      // Initialisation de la date de démarrage prévue de la dernière étape
      const nao = moment(new Date());
      projet_etape.value = new ProjetEtape();
      projet_etape.value!.id = projet.value!.etapesProjet![projet.value!.etapesProjet!.length - 1];
      const data = await apiClient.projet_etape.getProjetEtape(projet_etape.value);
      projet_etape.value = Object.assign(new ProjetEtape(), data);
      projet_etape.value!.dateDebutReelle = nao.format("DD/MM/YYYY HH:mm");
      projet_etape.value!.dateFinReelle = null;
      apiClient.projet_etape
        .sendProjetEtape(projet_etape.value!)
        .then((data: ProjetEtape) => {
          projet.value!.status = "En cours";
          //projet.value!.etapesProjet = [];
          saveProjet(false);
        });
    };

    const deleteProjet = () => {
      confirm.require({
        message: "Etes vous sûr de vouloir supprimer ce projet ?",
        header: "Suppression",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.projet
            .deleteProjet(projet.value!)
            .then((data: Projet) => {
              projet.value = data;
              toast.add({
                severity: "success",
                summary: "Suppression",
                detail: "Le projet a été supprimé.",
                life: 5000,
              });
              router.push({ path: "/projets" });
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    return {
      projet,
      toggle,
      op,
      saveProjet,
      lancerProjet,
      reactiverProjet,
      deleteProjet,
      newEtape,
      appUser,
      can: usersStore.can,
      saveAll,
      newEtapeExists,
      utilisateurs,
      valider_texte,
      text_ajout,
      allEtapes,
      etapesProjetLib,
      etapes,
      selectedEtape,
    };
  },
});
