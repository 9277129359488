// import Projet from "../projet/Projet"
// import { UtilisateurInterface } from "../utilisateurs/Utilisateur.interface"

import moment from "moment";

export default class ProjetEtape {
  id: string;
  projet?: string;
  acteur?: string;
  name?: string;
  couleur?: string;
  for_operateur?: boolean;
  for_referent?: boolean;
  dateDebutPrevue?: string | null;
  dateFinPrevue?: string | null;
  dateDebutReelle?: string;
  dateFinReelle?: string | null;

  constructor(){
      this.id = 'notSet';
  }

  get dateFinPrevueTimestamp(): string {
    return moment(this.dateFinPrevue, "DD/MM/YYYY HH:mm").format("X");
  }
}