
import { defineComponent, ref, onBeforeMount } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import Projet from "@/models/projet/Projet";
import Product from "@/models/product/Product";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Button from "primevue/button";
import Card from "primevue/card";
import apiClient from "@/api-client";
//import Chip from 'primevue/chip';
import moment from 'moment';

export default defineComponent({
  name: "ProjetsPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
    //Chip,
  },
  setup() {
    const usersStore = useUsersStore();
    const projets = ref<Projet[]>()
    const loading = ref(true);

    onBeforeMount(() => {
      loading.value = true;
      apiClient.projet
        .getProjets({'status': 'Nouveau'})
        .then((data: Projet[]) => {
          projets.value = data.map((projet) => Object.assign(new Projet(), projet));
          loading.value = false;
        });
    });

    // const onSelectProjet = (modeles_projet: Projet) => {
    //   console.log('ok')
    // };

    return {
      projets,
      // onSelectProjet,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
      moment,
      loading,
    };
  },
});
