
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import ProjetForm from '@/components/projet/ProjetForm.vue'

export default defineComponent({
  name: "ModeleProjetDetailPage",
  components: {
    ProjetForm
  },
  setup() {
    const route = useRoute();
    const projetId = route.params.id =='null' ? null : route.params.id;

    return {
      projetId
    };
  },
});
