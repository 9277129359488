import { ProjetApiClientModel, ProjetApiClientInterface, ProjetApiClientUrlsInterface } from '@/models/api-client/projet'

const urls: ProjetApiClientUrlsInterface = {
    getProjets: process.env.VUE_APP_API_URL + '/api/projets',
    getProjet: process.env.VUE_APP_API_URL + '/api/projets/$(projetId)',
    postProjet: process.env.VUE_APP_API_URL + '/api/projets',
    putProjet: process.env.VUE_APP_API_URL + '/api/projets/$(projetId)',
    deleteProjet: process.env.VUE_APP_API_URL + '/api/projets/$(projetId)',
    newProjetParams: process.env.VUE_APP_API_URL + '/api/projets/from_modele'
}

const projetApiClient: ProjetApiClientInterface = new ProjetApiClientModel(urls)
// export our instance
export default projetApiClient
