import ProjetEtape from '@/models/projet_etape/Projet_etape'
import Projet from '@/models/projet/Projet'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

export interface ProjetEtapeApiClientInterface {
    getProjetEtape: (projet_etape: ProjetEtape) => Promise<ProjetEtape>
    getProjetEtapes: (projet: Projet) => Promise<ProjetEtape[]>
    sendProjetEtape: (projet_etape: ProjetEtape) => Promise<ProjetEtape>
    deleteProjetEtape: (projet_etape: ProjetEtape) => Promise<ProjetEtape>
}

export interface ProjetEtapeApiClientUrlsInterface {
    getProjetEtape: string
    getProjetEtapes: string
    postProjetEtape: string
    putProjetEtape: string
    deleteProjetEtape: string
}

export class ProjetEtapeApiClientModel implements ProjetEtapeApiClientInterface {
    private readonly urls!: ProjetEtapeApiClientUrlsInterface
    constructor(urls: ProjetEtapeApiClientUrlsInterface) {
        this.urls = urls
    }

    getProjetEtape(projet_etape: ProjetEtape): Promise<ProjetEtape> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getProjetEtape.replace('$(projet_etapeId)', projet_etape.id!),
            requiresToken: false
        }
        return HttpClient.get<ProjetEtape>(getParameters)
    }

    getProjetEtapes(projet: Projet): Promise<ProjetEtape[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getProjetEtapes.replace('$(projetId)', projet.id!),
            requiresToken: false
        }
        return HttpClient.get<ProjetEtape[]>(getParameters)
    }

    sendProjetEtape(projet_etape: ProjetEtape): Promise<ProjetEtape> {
        if (projet_etape.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putProjetEtape.replace('$(projet_etapeId)', projet_etape.id!),
                requiresToken: false,
                payload: projet_etape,
            }
            return HttpClient.put<ProjetEtape>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
            url: this.urls.postProjetEtape.replace('$(projetId)', projet_etape.projet!),
            requiresToken: false,
            payload: projet_etape,
            }
            return HttpClient.post<ProjetEtape>(getParameters)
        }
    }
    deleteProjetEtape(projet_etape: ProjetEtape): Promise<ProjetEtape> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteProjetEtape.replace('$(projet_etapeId)', projet_etape.id!),
            requiresToken: false
        }
        return HttpClient.delete<ProjetEtape>(getParameters)
    }
}
