import { ApiClientInterface } from '@/api-client/ApiClient.interface'
import etapesApiClient from './etapes'
import usersApiClient from './users'
import utilisateursApiClient from './utilisateurs'
import auditsApiClient from './audits'
import modeles_projetApiClient from './modeles_projet'
import modeles_projet_etapeApiClient from './modele_projet_etape'
import productApiClient from './product'
import ligne_commandeApiClient from './ligne_commande'
import projetApiClient from './projet'
import projetEtapeApiClient from './projet_etape'

// create an instance of our main ApiClient that wraps the live child clients
const apiMockClient: ApiClientInterface = {
    users: usersApiClient,
    etapes: etapesApiClient,
    utilisateurs: utilisateursApiClient,
    audits: auditsApiClient,
    modeles_projet: modeles_projetApiClient,
    modeles_projet_etape: modeles_projet_etapeApiClient,
    product: productApiClient,
    lignes_commande: ligne_commandeApiClient,
    projet: projetApiClient,
    projet_etape: projetEtapeApiClient,
}
// export our instance
export default apiMockClient
