
import { defineComponent, ref, onMounted, computed } from "vue";
import { UtilisateurInterface } from "@/models/utilisateurs/Utilisateur.interface";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import apiClient from "@/api-client";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";
import Invitation from "@/models/users/Invitation";
import { UserInterface } from "@/models/users/User.interface";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  name: "UtilisateursPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
  },
  setup() {
    const utilisateurs = ref<UtilisateurInterface[]>();
    const usersStore = useUsersStore();
    const user = computed(() => {
      return usersStore.state.appUser;
    });
    const loading = ref(true);
    const invitation = ref(new Invitation());
    const toast = useToast();

    onMounted(() => {
      loading.value = true;
      apiClient.utilisateurs
        .fetchUtilisateurs()
        .then((data: UtilisateurInterface[]) => {
          utilisateurs.value = data;
          loading.value = false;
        });
    });

    

    const inviterUtilisateur = (data: any) => {
      invitation.value.id = data.id;
      data.statut = 0;
      apiClient.users
        .inviterUtilisateur(invitation.value)
        .then((data: UserInterface) => {
          toast.add({
            severity: "success",
            summary: "Invitation envoyée",
            detail: "Un mail a été envoyé à l'utilisateur invité pour qu'il puisse valider son compte.",
            life: 5000,
          });
        });
    };

    return {
      utilisateurs,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
      loading,
      inviterUtilisateur,
      invitation,
    };
  },
});
