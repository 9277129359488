import LigneCommande from '@/models/ligne_commande/Ligne_commande'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

export interface LigneCommandeApiClientInterface {
    getLignesCommande: () => Promise<LigneCommande[]>
}

export interface LigneCommandeApiClientUrlsInterface {
    getLignesCommande: string
}

/**
* @Name LigneCommandeApiClientModel
* @description
* Implements the LigneCommandeApiClientInterface interface
*/
export class LigneCommandeApiClientModel implements LigneCommandeApiClientInterface {
    private readonly urls!: LigneCommandeApiClientUrlsInterface
    constructor(urls: LigneCommandeApiClientUrlsInterface) {
        this.urls = urls
    }

    getLignesCommande(): Promise<LigneCommande[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getLignesCommande,
            requiresToken: false
        }
        return HttpClient.get<LigneCommande[]>(getParameters)
    }
}
