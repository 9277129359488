
import { defineComponent, computed } from "vue";
import Menu from "primevue/menu";
import AppInfo from "@/components/app-info/AppInfo.vue";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  name: "DashboardLeftMenu",
  components: {
    Menu,
    AppInfo,
  },
  setup() {
    const usersStore = useUsersStore();
    const appUser = computed(() => {
      return usersStore.state.appUser;
    });
    const items = computed(() => {
      let odooUrl;
      if (process.env.VUE_APP_ENV=='production') {
        odooUrl = "https://odoo.espritcom.fr"
      } else {
        odooUrl = "https://testodoo.espritcom.fr"
      }
      return [
        {
          label: "Projets Clients",
          items: [
            {
              label: "Projets à créer",
              icon: "pi pi-star",
              to: "/lignes_commande",
              visible: appUser.value.is_admin_projets || appUser.value.is_referent,
            },
            {
              label: "Projets à lancer",
              icon: "pi pi-send",
              to: "/projets",
              visible: appUser.value.is_admin_projets || appUser.value.is_referent,
            },
            {
              label: "Projets en cours",
              icon: "pi pi-clock",
              to: "/todolist",
            },
            {
              label: "Projets terminés",
              icon: "pi pi-dollar",
              to: "/projets_termines",
              visible: appUser.value.is_admin_projets,
            },

          ],

        },
        {
          label: "Paramètres",
          items: [
            {
              label: "Modèles de projet",
              icon: "pi pi-clone",
              to: "/modeles_projet",
            },
            {
              label: "Etapes",
              icon: "pi pi-th-large",
              to: "/etapes",
            },
            {
              label: "Utilisateurs",
              icon: "pi pi-user",
              to: "/utilisateurs",
            },
            // {
            //   label: "Audit",
            //   icon: "pi pi-eye",
            //   to: "/audits",
            // },
          ],
          visible: appUser.value.is_admin_modeles
        },
        {
          label: "Liens",
          items: [
            {
              label: "Odoo",
              icon: "pi pi-external-link",
              url: odooUrl,
              target: "_blank",
            },
          ],
        },
      ];
    });

    return {
      items
    };
  },
});
