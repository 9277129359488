import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")
  const _component_AppInfo = _resolveComponent("AppInfo")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Menu, {
      model: _ctx.items,
      style: {"width":"100%"}
    }, null, 8, ["model"]),
    _createVNode(_component_AppInfo)
  ], 64))
}