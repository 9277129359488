
import { defineComponent, ref, onBeforeMount } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import Projet from "@/models/projet/Projet";
import Product from "@/models/product/Product";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Button from "primevue/button";
import Card from "primevue/card";
import apiClient from "@/api-client";
//import Chip from 'primevue/chip';
import moment from 'moment';
import Paginator from 'primevue/paginator';

export default defineComponent({
  name: "ProjetsPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
    //Chip,
    Paginator,
  },
  setup() {
    const usersStore = useUsersStore();
    const projets = ref<Projet[]>()
    const loading = ref(true);
    const offset = ref<number>(0);
    const rows = ref<number>(15);
    const totalRecords = ref<number>(0);

    apiClient.projet
      .getProjets({status: 'Terminé', stats: true})
      .then((data: any) => {
        totalRecords.value = data.length;
      });

    const loadProjets = () => {
      loading.value = true;
      apiClient.projet
        .getProjets({'status': 'Terminé', paginator: {offset: offset.value, rows: rows.value}})
        .then((data: Projet[]) => {
          projets.value = data.map((projet) => Object.assign(new Projet(), projet));
          loading.value = false;
        });
    }

    loadProjets();

    const onPage = (event: any) => {
        offset.value = event.page * rows.value;
        loadProjets();
    }

    return {
      projets,
      // onSelectProjet,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
      moment,
      loading,
      offset,
      totalRecords,
      rows,
      onPage,
    };
  },
});
