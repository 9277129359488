
import { defineComponent, ref, onBeforeMount } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import ModeleProjet from "@/models/modele_projet/Modele_projet";
import Product from "@/models/product/Product";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Button from "primevue/button";
import Card from "primevue/card";
import apiClient from "@/api-client";
//import Chip from 'primevue/chip';

export default defineComponent({
  name: "ModelesProjetPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
    //Chip,
  },
  setup() {
    const usersStore = useUsersStore();
    const modeles_projet = ref<ModeleProjet[]>()
    const products = ref<Product[]>();
    const loading = ref(true);

    apiClient.product
        .getProducts()
        .then((data: Product[]) => {
          products.value = data.map((product) => Object.assign(new Product(), product));
        });

    onBeforeMount(() => {
      loading.value = true;
      apiClient.modeles_projet
        .getModelesProjet()
        .then((data: ModeleProjet[]) => {
          modeles_projet.value = data.map((modele_projet) => Object.assign(new ModeleProjet(), modele_projet));
          loading.value = false;
        });
    });

    const onSelectModeleProjet = (modeles_projet: ModeleProjet) => {
      console.log('ok')
    };

    return {
      modeles_projet,
      onSelectModeleProjet,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
      products,
      loading,
    };
  },
});
