
import { defineComponent, ref, computed, watch} from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import ProjetEtape from "@/models/projet_etape/Projet_etape";
import Projet from "@/models/projet/Projet";
// import Etape from "@/models/etapes/Etape";
import { UtilisateurInterface } from "@/models/utilisateurs/Utilisateur.interface";
import apiClient from "@/api-client";
import { useUsersStore } from "@/store/users";
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import ColorPicker from 'primevue/colorpicker';
import Calendar from 'primevue/calendar';
import {Mutex} from 'async-mutex';
import moment from 'moment';
import router from "@/router";

export default defineComponent({
  components: {
    InputText,
    Button,
    Dropdown,
    InputSwitch,
    ColorPicker,
    Calendar
  },
  props: {
    etapeProjetId: {
      type: String,
    },
    bSave: {
      type: Boolean,
    },
    projet: {
      type: Projet,
    },
    idx: {
      type: Number,
    },
    bAllEtapes: {
      type: Boolean,
    },
    etapesProjetLib: {
      type: Object,
    },
  },
  setup(props) {
    const toast = useToast();
    const confirm = useConfirm();
    const projet_etape = ref<ProjetEtape>();
    const projet_etape_suivante = ref<ProjetEtape>();
    projet_etape.value = new ProjetEtape();
    projet_etape.value!.id = props.etapeProjetId!;
    const usersStore = useUsersStore();
    const acteurs = ref<UtilisateurInterface[]>();
    const mutex = new Mutex();
    const autresEtapes = ref<Record<string, any>>();
    const etapeSuivanteId = ref<string>();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    const saveProjetEtape = () => {
      apiClient.projet_etape
          .sendProjetEtape(projet_etape.value!)
          .then((data: ProjetEtape) => {
          projet_etape.value = data;
        });
    };

    const refreshActeurs = async (type: string) => {
        const release = await mutex.acquire();

        acteurs.value = [];
        projet_etape.value!.acteur = undefined;

        if (type=='OPE' && !projet_etape.value!.for_operateur) projet_etape.value!.for_referent = true;
        if (type=='REF' && !projet_etape.value!.for_referent) projet_etape.value!.for_operateur = true;
        else projet_etape.value!.acteur = props.projet!.referent;

        apiClient.utilisateurs
        .fetchUtilisateurs({"is_operateur": projet_etape.value!.for_operateur, "is_referent": projet_etape.value!.for_referent})
        .then((data: UtilisateurInterface[]) => {
          acteurs.value = data.map((utilisateur) => Object.assign(new UtilisateurInterface(), utilisateur));
          release();
        });
    };

    if (props.etapeProjetId != 'notSet') {
      apiClient.projet_etape
        .getProjetEtape(projet_etape.value!)
        .then((data: ProjetEtape) => {
          projet_etape.value = Object.assign(new ProjetEtape(),data);
          apiClient.utilisateurs
            .fetchUtilisateurs({"is_operateur": projet_etape.value!.for_operateur, "is_referent": projet_etape.value!.for_referent})
            .then((data: UtilisateurInterface[]) => {
              acteurs.value = data.map((utilisateur) => Object.assign(new UtilisateurInterface(), utilisateur));

              autresEtapes.value = props.etapesProjetLib!.filter((elem: any) => elem.id != projet_etape.value!.id);
              autresEtapes.value!.push({id: 'end', name: '< Terminer le projet >'});
              if (props.idx! < props.projet!.etapesProjet!.length - 1) {
                etapeSuivanteId.value = props.projet!.etapesProjet![props.idx! + 1];
              } else {
                etapeSuivanteId.value = 'end';
              }
            });
        });
    }
    else {
      // modele_projet_etape.value!.modeleProjet = props.modele_projet!.id!;
      projet_etape.value!.projet = props.projet!.id!;
      projet_etape.value!.name = "Nouvelle étape"
      projet_etape.value!.couleur = "808080";
      projet_etape.value!.for_operateur = true;
      projet_etape.value!.for_referent = false;
      apiClient.utilisateurs
        .fetchUtilisateurs({"is_operateur": projet_etape.value!.for_operateur, "is_referent": projet_etape.value!.for_referent})
        .then((data: UtilisateurInterface[]) => {
          acteurs.value = data.map((utilisateur) => Object.assign(new UtilisateurInterface(), utilisateur));

        });

      // selectedEtape.value = etapes.value![0];
      // modele_projet_etape.value!.etape = selectedEtape.value.id;
      // modele_projet_etape.value!.noOrdre = props.modele_projet!.etapesModele!.length
    }


    // apiClient.utilisateurs
    //   .fetchUtilisateurs()
    //   .then((data: UtilisateurInterface[]) => {
    //     acteurs.value = data;
    //   });

    watch(() => props.bSave, (bSave) => {
      if(bSave) {
        saveProjetEtape();
      }
    });

    // watch(() => projet_etape.value!.etape, (etapeId) => {
    //     selectedEtape.value = etapes.value!.filter(elem => elem.id == etapeId)[0];
    // });


    const deleteProjetEtape = () => {
      confirm.require({
        message: "Etes vous sûr de vouloir supprimer cette étape ?",
        header: "Suppression",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (projet_etape.value!.id != 'notSet') {
            apiClient.projet_etape
              .deleteProjetEtape(projet_etape.value!)
              .then((data: ProjetEtape) => {
                projet_etape.value = data;
                toast.add({
                  severity: "success",
                  summary: "Suppression",
                  detail: "L'étape a été supprimée.",
                  life: 5000,
                });
                props.projet!.etapesProjet!.splice(props.idx!, 1);
              });
          } else {
            props.projet!.etapesProjet!.splice(props.idx!, 1);
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    const passerEtapeSuivante = () => {
      const nao = moment(new Date());
      projet_etape.value!.dateFinReelle = nao.format("DD/MM/YYYY HH:mm");
      apiClient.projet_etape
        .sendProjetEtape(projet_etape.value!)
        .then((data: ProjetEtape) => {
          if (etapeSuivanteId.value != 'end') {
            projet_etape_suivante.value = new ProjetEtape();
            projet_etape_suivante.value!.id = etapeSuivanteId.value!;
            apiClient.projet_etape
              .getProjetEtape(projet_etape_suivante.value!)
              .then((data: ProjetEtape) => {
                projet_etape_suivante.value = Object.assign(new ProjetEtape(),data);
                projet_etape_suivante.value.dateDebutReelle = nao.format("DD/MM/YYYY HH:mm");
                projet_etape_suivante.value.dateFinReelle = null;
                apiClient.projet_etape
                  .sendProjetEtape(projet_etape_suivante.value!)
                  .then((data: ProjetEtape) => {
                    router.go(0);
                  });
              });
          } else {
            props.projet!.dateFin = nao.format("DD/MM/YYYY HH:mm");
            props.projet!.status = "Terminé";
            apiClient.projet
              .sendProjet(props.projet!)
              .then((data: Projet) => {
                router.go(0);
              });
          }
        });
    };

    return {
      projet_etape,
      deleteProjetEtape,
      appUser,
      can: usersStore.can,
      // etapes,
      acteurs,
      refreshActeurs,
      // selectedEtape,
      passerEtapeSuivante,
      autresEtapes,
      etapeSuivanteId,
      moment,
    };
  },
});
