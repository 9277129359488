import ModeleProjet from '@/models/modele_projet/Modele_projet'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

export interface ModelesProjetApiClientInterface {
    getModelesProjet: (apiFilters?: any) => Promise<ModeleProjet[]>
    getModeleProjet: (modele_projet: ModeleProjet) => Promise<ModeleProjet>
    sendModeleProjet: (modele_projet: ModeleProjet) => Promise<ModeleProjet>
    deleteModeleProjet: (modele_projet: ModeleProjet) => Promise<ModeleProjet>
}

export interface ModelesProjetApiClientUrlsInterface {
    getModelesProjet: string
    getModeleProjet: string
    postModeleProjet: string
    putModeleProjet: string
    deleteModeleProjet: string
}

export class ModelesProjetApiClientModel implements ModelesProjetApiClientInterface {
    private readonly urls!: ModelesProjetApiClientUrlsInterface
    constructor(urls: ModelesProjetApiClientUrlsInterface) {
        this.urls = urls
    }

    getModelesProjet(apiFilters?: any): Promise<ModeleProjet[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getModelesProjet,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<ModeleProjet[]>(getParameters)
    }
    getModeleProjet(modele_projet: ModeleProjet): Promise<ModeleProjet> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getModeleProjet.replace('$(modele_projetId)', modele_projet.id!),
            requiresToken: false
        }
        return HttpClient.get<ModeleProjet>(getParameters)
    }
    sendModeleProjet(modele_projet: ModeleProjet): Promise<ModeleProjet> {
        if (modele_projet.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putModeleProjet.replace('$(modele_projetId)', modele_projet.id!),
                requiresToken: false,
                payload: modele_projet,
            }
            return HttpClient.put<ModeleProjet>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postModeleProjet,
                requiresToken: false,
                payload: modele_projet,
            }
            return HttpClient.post<ModeleProjet>(getParameters)
        }
    }
    deleteModeleProjet(modele_projet: ModeleProjet): Promise<ModeleProjet> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteModeleProjet.replace('$(modele_projetId)', modele_projet.id!),
            requiresToken: false
        }
        return HttpClient.delete<ModeleProjet>(getParameters)
    }
}
